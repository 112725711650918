/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Alert
} from 'reactstrap';

import HttpRequest from "request";
import Utils from "../../utils/Utils.js";
import config from "../../config.js";


class ComparePositionsComp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      algoPositions: props.algoPositions,
      brokerPositions: props.brokerPositions,
      fetchPositionsInProgress: false,
      squareOffInProgress: false,
      statusMessage: null,
      error: null
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      algoPositions: nextProps.algoPositions,
      brokerPositions: nextProps.brokerPositions
    })
  }

  refreshAllPositions() {
    this.setState({
      fetchPositionsInProgress: true,
      statusMessage: null,
      error: null
    });
    this.props.fetchAllTrades(this.props.username, this.props.broker).then(result => {
      console.log('fetchAllTrades: result => ', result);
      this.setState({
        algoPositions: result.trades.algoPositions,
        brokerPositions: result.trades.brokerPositions,
        fetchPositionsInProgress: false
      });
    }).catch(err => {
      this.setState({
        fetchPositionsInProgress: false,
        error: err
      });
    });
  }

  exitPositionsDiffQty(positions = []) {
    this.setState({
      squareOffInProgress: true
    });

    const data = {
      username: this.props.username,
      broker: this.props.broker,
      clientID: this.props.clientID,
      positions: []
    };

    data.positions = _.map(positions, pos => {
      return {
        tradingSymbol: pos.tradingSymbol,
        direction: pos.qtyDiff > 0 ? "LONG" : "SHORT",
        qty: Math.abs(pos.qtyDiff),
        productType: pos.productType,
        exchange: pos.exchange,
        segment: pos.segment
      }
    });

    HttpRequest.post({
      url: config.serverHost + "/apis/positions/exit",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('exitPositionsDiffQty status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          statusMessage: "Order Ids: " + respBody + ". Pl refresh after some time.",
          squareOffInProgress: false
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          error: 'exitPositionsDiffQty end point not found',
          squareOffInProgress: false
        });
      } else {
        console.error('exitPositionsDiffQty error => ', respBody);
        this.setState({
          error: JSON.parse(respBody).error || JSON.parse(respBody).result,
          squareOffInProgress: false
        });
      }
    });
  }
 
  preparePositions() {
    let positions = {};
    _.each(this.state.algoPositions, ap => {
      if ((this.props.product === 'INTRADAY' && ap.productType !== 'MIS') 
        || (this.props.product === 'POSITIONAL' && ap.productType !== 'NRML')) {
        return;
      }
      const key = ap.tradingSymbol + '-' + ap.productType;
      positions[key] = {
        exchange: ap.exchange,
        segment: ap.segment,
        tradingSymbol: ap.tradingSymbol,
        productType: ap.productType,
        notFromAlgo: false,
        notFromBroker: false,
        algoQty: ap.netQty,
        algoPnl: ap.totalPnl,
        algoPnlByEOD: ap.totalPnlByEOD,
        algoAvgPrice: ap.netQty > 0 ? ap.buyAvgPrice : (ap.netQty < 0 ? ap.sellAvgPrice : 0),
        brokerQty: 0,
        brokerPnl: 0,
        brokerAvgPrice: 0
      };
    });
    _.each(this.state.brokerPositions, bp => {
      if ((this.props.product === 'INTRADAY' && bp.productType !== 'MIS') 
        || (this.props.product === 'POSITIONAL' && bp.productType !== 'NRML')) {
        return;
      }
      const key = bp.tradingSymbol + '-' + bp.productType;
      if (positions[key]) { // symbol exists in broker pnl
        positions[key].brokerQty = bp.netQty;
        positions[key].brokerPnl = bp.totalPnl;
        positions[key].brokerAvgPrice = bp.netQty > 0 ? bp.buyAvgPrice : (bp.netQty < 0 ? bp.sellAvgPrice : 0);
        positions[key].exchange = bp.exchange;
        positions[key].segment = bp.segment;
      } else { // not exists in algo
        positions[key] = {
          exchange: bp.exchange,
          segment: bp.segment,
          tradingSymbol: bp.tradingSymbol,
          productType: bp.productType,
          notFromAlgo: true,
          algoQty: 0,
          algoPnl: 0,
          algoPnlByEOD: 0,
          algoAvgPrice: 0,
          brokerQty: bp.netQty,
          brokerPnl: bp.totalPnl,
          brokerAvgPrice: bp.netQty > 0 ? bp.buyAvgPrice : (bp.netQty < 0 ? bp.sellAvgPrice : 0)
        };
      }
    });
    // find a position that exists in algo but not in broker and set notFromBroker to true
    _.each(positions, pos => {
      if (!pos.notFromAlgo) {
        const brokerPos = _.find(this.state.brokerPositions, bp => {
          return bp.productType === pos.productType && bp.tradingSymbol === pos.tradingSymbol;
        });
        if (!brokerPos) {
          pos.notFromBroker = true;
        }
      }
    });

    _.each(positions, pos => {
      pos.qtyDiff = pos.brokerQty - pos.algoQty;
      pos.pnlDiff = pos.brokerPnl - pos.algoPnl;
      pos.noOpenQty = pos.algoQty === 0 && pos.brokerQty === 0;
    });
    positions = _.toArray(positions);
    positions = positions.sort((p1, p2) => {
      if (p1.productType !== p2.productType) {
        return p1.productType.localeCompare(p2.productType);
      }

      if (p1.notFromAlgo !== p2.notFromAlgo) {
        return !p1.notFromAlgo ? -1 : 1;
      }
      if (p1.notFromBroker !== p2.notFromBroker) {
        return !p1.notFromBroker ? -1 : 1;
      }
      if (p1.noOpenQty !== p2.noOpenQty) {
        return !p1.noOpenQty ? -1 : 1;
      }
      if (p1.tradingSymbol === p2.tradingSymbol) {
        return p1.productType.localeCompare(p2.productType);
      }

      const startChars1 = p1.tradingSymbol.substring(0, 5);
      const startChars2 = p2.tradingSymbol.substring(0, 5);
      if (startChars1 !== startChars2) {
        return startChars1.localeCompare(startChars2);
      }
        
      const direction1 = p1.algoQty > 0 ? 'B' : (p1.algoQty < 0 ? 'S' : '');
      const direction2 = p2.algoQty > 0 ? 'B' : (p2.algoQty < 0 ? 'S' : '');
          
      return `${direction1}-${p1.tradingSymbol}`.localeCompare(`${direction2}-${p2.tradingSymbol}`);
    });
    return positions;
  }

  renderPositions(positions) {
    const showPnlDiff = true;//this.props.broker === 'zerodha' || this.props.broker === 'fyers' ? true : false;
    let totalAlgoPnl = 0, totalAlgoPnlByEOD = 0, totalBrokerPnl = 0, totalDiffPnl = 0;
    _.each(positions, pos => {
      totalAlgoPnl += pos.algoPnl;
      totalAlgoPnlByEOD += pos.algoPnlByEOD;
      totalBrokerPnl += pos.brokerPnl;
    });
    totalDiffPnl = totalBrokerPnl - totalAlgoPnl;

    return (<Card>
      <CardHeader>
        Positions
      </CardHeader>
      <CardBody>
        <Table className="no-wrap v-middle" size="sm" responsive>
          <thead>
            <tr className="border-0">
              <th className="border-0">Symbol</th>
              <th className="border-0">Product</th>
              <th className="border-0 number-right">Algo Avg</th>
              <th className="border-0 number-right">Broker Avg</th>
              {showPnlDiff && <th className="border-0 number-right">Algo Pnl</th>}
              {showPnlDiff && <th className="border-0 number-right">Algo Pnl (By Prev Close)</th>}
              {showPnlDiff && <th className="border-0 number-right">Broker Pnl</th>}
              {showPnlDiff && <th className="border-0 number-right">Algo Broker Pnl Diff</th>}
              <th className="border-0 number-right">Algo Qty</th>
              <th className="border-0 number-right">Broker Qty</th>
              <th className="border-0 number-right">Qty Dif</th>
              {!this.props.disableActions && <th className="border-0 number-right">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {
              _.map(positions, (pos) => {
                const directionText = pos.algoQty > 0 ? 'B' : (pos.algoQty < 0 ? 'S' : null);
                const directionTextClassName = directionText === 'B' ? 'text-primary' : (directionText === 'S' ? 'text-orange' : '');
                return (<tr key={pos.tradingSymbol} style={pos.notFromAlgo || pos.notFromBroker ? {background: 'rgb(243, 238, 199)'} : (pos.noOpenQty ? {background: 'rgb(242, 245, 240)'} : {})}> 
                  <td className={directionTextClassName}>{pos.tradingSymbol + (directionText ? ' (' + directionText  + ')' : '')}</td>
                  <td>{pos.productType}</td>
                  <td className="number-right">{Utils.roundOff(pos.algoAvgPrice)}</td>
                  <td className="number-right">{Utils.roundOff(pos.brokerAvgPrice)}</td>
                  {showPnlDiff && <td className="number-right">{Utils.formatNumberToCommaSeparated(pos.algoPnl)}</td>}
                  {showPnlDiff && <td className="number-right">{Utils.formatNumberToCommaSeparated(pos.algoPnlByEOD)}</td>}
                  {showPnlDiff && <td className="number-right">{Utils.formatNumberToCommaSeparated(pos.brokerPnl)}</td>}
                  {showPnlDiff && <td className="number-right">{Utils.formatNumberToCommaSeparated(pos.pnlDiff)}</td>}
                  <td className="number-right">{pos.algoQty}</td>
                  <td className="number-right">{pos.brokerQty}</td>
                  <td className={"number-right" + (pos.qtyDiff !== 0 ? " text-danger" : "")}>{pos.qtyDiff}</td>
                  {!this.props.disableActions && <td className="number-right">
                    <Button color="primary"
                      disabled={pos.qtyDiff === 0}
                      onClick={() => this.exitPositionsDiffQty([pos])}>
                      Exit Diff
                    </Button>
                  </td>}
                </tr>);
              })
            }
            {showPnlDiff && <tr>
              <td colSpan="4">TOTAL</td>
              <td className="number-right">{Utils.formatNumberToCommaSeparated(totalAlgoPnl)}</td>
              <td className="number-right">{Utils.formatNumberToCommaSeparated(totalAlgoPnlByEOD)}</td>
              <td className="number-right">{Utils.formatNumberToCommaSeparated(totalBrokerPnl)}</td>
              <td className="number-right">{Utils.formatNumberToCommaSeparated(totalDiffPnl)}</td>
              <td colSpan="3"></td>
            </tr>}
          </tbody>
        </Table>
      </CardBody>
    </Card>);
  }

  render() {
    const positions = this.preparePositions();
    const showAlert = this.state.error || this.state.statusMessage || this.state.squareOffInProgress;
    const positionsWithDiffQty = _.filter(positions, pos => pos.qtyDiff !== 0);

    return (<div>
      {!this.props.disableActions && <Button 
        color="primary" 
        disabled={positionsWithDiffQty.length === 0}
        style={{'marginBottom': '5px'}}
        onClick={() => this.exitPositionsDiffQty(positionsWithDiffQty)}>
          Exit All Diff
      </Button>}

      {!this.props.disableActions && <i onClick={() => this.refreshAllPositions()} 
        className={this.state.fetchPositionsInProgress ? "fa fa-refresh fa-spin" : "fa fa-refresh"} 
        style={{'marginLeft': '15px', 'fontSize': '20px', 'cursor': 'pointer'}}>
      </i>}

      {showAlert && <Alert color={this.state.error ? "danger" : "success"}>
        <div style={{'marginTop': '10px', 'marginBottom': '10px'}} >
          {this.state.error && <div className="text-orange">{this.state.error}</div>}
          {this.state.statusMessage && <div className="text-success">{this.state.statusMessage}</div>}
          {this.state.fetchPositionsInProgress && <div>Fetching positions is in progress. Please wait...</div>}
          {this.state.squareOffInProgress && <div>Exit positions is in progress. Please wait...</div>}
        </div>
      </Alert>}

      {this.renderPositions(positions)}

    </div>);
  }
}

export default ComparePositionsComp;
