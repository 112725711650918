/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from "react";
import TradeRow from "./TradeRow.js";
import Utils from "../../utils/Utils.js";

import {
  Card,
  CardBody,
  //CardTitle,
  Table
} from 'reactstrap';


class CancelledTrades extends React.Component {
 
  constructor(props) {
    super(props);

    this.cols = [{
      name: 'SNo',
      key: 'sno'
    }, {
      name: 'TradeID',
      key: 'tradeID'
    }, {
      name: 'Strategy',
      key: 'strategy'
    }, {
      name: 'Product',
      key: 'productType'
    }, {
      name: 'Group',
      key: 'group'
    }, {
      name: 'Symbol',
      key: 'tradingSymbol'
    }, {
      name: 'Direction',
      key: 'direction'
    }, {
      name: 'Order Placed',
      key: 'startTimestamp',
      type: 'timestamp'
    }, {
      name: 'Qty',
      key: 'quantity',
    }, {
      name: 'Req Entry',
      key: 'requestedEntry',
      alignRight: true
    }, {
      name: 'Stop Loss',
      key: 'stopLoss',
      alignRight: true
    }, {
      name: 'Remarks',
      key: 'remarks'
    }, {
      name: 'Original Order',
      key: 'order',
      getValue: (trade) => {
        return Utils.getOrderDetailsDisplayValue(trade.order);
      }
    }, {
      name: 'SL Order',
      key: 'slOrder',
      getValue: (trade) => {
        return Utils.getOrderDetailsDisplayValue(trade.slOrder);
      }
    }, {
      name: 'Target Order',
      key: 'targetOrder',
      getValue: (trade) => {
        return Utils.getOrderDetailsDisplayValue(trade.targetOrder);
      }
    }];

    if (this.props.showLess) {
      this.cols = _.filter(this.cols, col => {
        if (col.key === 'stopLoss' || col.key === 'target'
          || col.key === 'requestedEntry' || col.key === 'exitReason'
          || col.key === 'remarks' 
          || col.key === 'slOrder' || col.key === 'targetOrder') {
          return false;
        }
        return true;
      });
    }
  }

  render() {
    let trades = Utils.sortTrades(this.props.trades);
    trades = _.map(trades, (trade, index) => {
      return {
        ...trade,
        sno: index + 1
      }
    });

    return (<Card>
      <CardBody>
        {/*<div className="d-flex align-items-center">
          <div>
            <CardTitle>Cancelled Trades</CardTitle>
          </div>
        </div>*/}
        <Table className="no-wrap v-middle" size="sm" responsive>
          <thead>
            <tr className="border-0">
              {
                this.cols.map((col, index) => {
                  return (<th className={col.alignRight ? "border-0 number-right" : "border-0"} key={index}>{col.name}</th>);
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              trades.map(td => {
                return (
                  <TradeRow 
                    cols={this.cols}
                    trade={td}
                    key={"trade" + td.sno} />
                );
              })
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>);
  }
}

export default CancelledTrades;

