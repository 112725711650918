/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Button 
} from 'reactstrap';

import ComparePositionsComp from '../common/ComparePositionsComp.js';

class ComparePositionsModal extends React.Component {

  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
  }

  render() {

    return (<div>
      <Modal 
        size="lg"
        style={{maxWidth: '1600px', width: '80%'}} 
        isOpen={this.props.isModalOpen}
        >
        <ModalHeader>{this.props.product || 'ALL'}: Compare Positions (Algo Vs Broker) for {this.props.username + '-' + _.toUpper(this.props.broker) + '-' + this.props.clientID}</ModalHeader>
        
        <ModalBody>
          <ComparePositionsComp
            product={this.props.product}
            algoPositions={this.props.algoPositions}
            brokerPositions={this.props.brokerPositions}
            username={this.props.username}
            broker={this.props.broker}
            clientID={this.props.clientID}
            fetchAllTrades={this.props.fetchAllTrades} />
        </ModalBody>

        <ModalFooter>
          <Button 
            color="secondary" 
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>

      </Modal>
    </div>);
  }
}

export default ComparePositionsModal;