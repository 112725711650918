/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import React from 'react';

import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  TabContent,
  TabPane, 
  Nav, 
  NavItem, 
  NavLink,
  Button 
} from 'reactstrap';

import classnames from 'classnames';

import OpenTrades from '../trades/OpenTrades.js';
import ActiveTrades from '../trades/ActiveTrades.js';
import CompletedTrades from '../trades/CompletedTrades.js';
import CancelledTrades from '../trades/CancelledTrades.js';
import TrackLostTrades from '../trades/TrackLostTrades.js';

class TradesModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTabTitle: this.props.type || 'Active Trades'
    };

    this.getOpenTrades = this.getOpenTrades.bind(this);
    this.getActiveTrades = this.getActiveTrades.bind(this);
    this.getCompletedTrades = this.getCompletedTrades.bind(this);
    this.getCancelledTrades = this.getCancelledTrades.bind(this);
    this.getTrackLostTrades = this.getTrackLostTrades.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  getOpenTrades() {
    return (<OpenTrades trades={this.props.allTrades.openTrades}/>);
  }

  getActiveTrades() {
    return (<ActiveTrades 
      trades={this.props.allTrades.activeTrades}
      allowAlterTrades={true}
    />);
  }

  getCompletedTrades() {
    return (<CompletedTrades 
      trades={this.props.allTrades.completedTrades}
      allowAlterTrades={true}
    />);
  }

  getCancelledTrades() {
    return (<CancelledTrades trades={this.props.allTrades.cancelledTrades}/>);
  }

  getTrackLostTrades() {
    return (<TrackLostTrades trades={this.props.allTrades.trackLostTrades}/>);
  }

  toggle(tab) {
    if (tab.title === this.state.activeTabTitle) {
      return;
    }
    this.setState({
      activeTabTitle: tab.title
    });
  }

  onCancel() {
    if (_.isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
  }

  render() {

    const tabs = [{
      title: 'Open Trades',
      getContent: this.getOpenTrades,
      tradesCount: this.props.allTrades.openTrades.length
    }, {
      title: 'Active Trades',
      getContent: this.getActiveTrades,
      tradesCount: this.props.allTrades.activeTrades.length
    }, {
      title: 'Completed Trades',
      getContent: this.getCompletedTrades,
      tradesCount: this.props.allTrades.completedTrades.length
    }, {
      title: 'Cancelled Trades',
      getContent: this.getCancelledTrades,
      tradesCount: this.props.allTrades.cancelledTrades.length
    }/*, {
      title: 'Tracklost Trades',
      getContent: this.getTrackLostTrades,
      tradesCount: this.props.allTrades.trackLostTrades.length
    }*/];

    return (<div>
      <Modal 
        size="lg"
        style={{maxWidth: '1600px', width: '80%'}} 
        isOpen={this.props.isModalOpen}
        >
        <ModalHeader>Trade Details of {this.props.username + '-' + _.toUpper(this.props.broker) + '-' + this.props.clientID}</ModalHeader>
        
        <ModalBody>
          <div>
            <Nav tabs>
              {
                _.map(tabs, (tab) => {
                  return (<NavItem key={tab.title}>
                    <NavLink
                      className={classnames({ active: this.state.activeTabTitle === tab.title })}
                      onClick={() => { this.toggle(tab); }}
                    >
                      {`${tab.title} (${tab.tradesCount})`}
                    </NavLink>
                  </NavItem>);
                })
              }
            </Nav>
            <TabContent activeTab={this.state.activeTabTitle}>
              <TabPane tabId={this.state.activeTabTitle}>
                {
                  (_.find(tabs, tab => tab.title === this.state.activeTabTitle) || {}).getContent()
                }
              </TabPane>
            </TabContent>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button 
            color="secondary" 
            onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>

      </Modal>
    </div>);
  }
}

export default TradesModal;