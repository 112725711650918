
import _ from 'lodash';
import config from "../config.js";
import superagent from 'superagent';
import HttpRequest from "request";


export const getBuildInfoOfServer = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/buildinfo", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getBuildInfoOfServer: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getBuildInfoOfServer: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getBuildInfoOfServer: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch server build info');
        }
      } else {
        reject(`getBuildInfoOfServer: No error and no response`);
      }
    });
  });
};

export const getUserDetails = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/user", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getUserDetails: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getUserDetails: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getUserDetails: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch user details');
        }
      } else {
        reject(`getUserDetails: No error and no response`);
      }
    });
  });
};

export const logoutUser = () => {
  return new Promise((resolve, reject) => {
    const data = {};
    HttpRequest.post({
      url: config.serverHost + "/logout",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      if (err) {
        console.log('logoutUser: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('logoutUser: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          resolve({status: "success"});
        } else if (resp.statusCode === 404) {
          reject('logoutUser: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to logout user');
        }
      } else {
        reject(`logoutUser: No error and no response`);
      }
    });
  });
};

export const changeUserPassword = (data = {}) => {
  return new Promise((resolve, reject) => {
    HttpRequest.put({
      url: config.serverHost + "/apis/user/password",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      if (err) {
        console.log('changeUserPassword: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('changeUserPassword: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('changeUserPassword: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to change user password');
        }
      } else {
        reject(`changeUserPassword: No error and no response`);
      }
    });
  });
};

export const closeAccount = (username) => {
  return new Promise((resolve, reject) => {
    HttpRequest.delete({
      url: config.serverHost + "/apis/user?username=" + username,
      headers: { 'Content-Type': 'application/json' }
    }, (err, resp, respBody) => {
      if (err) {
        console.log('closeAccount: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('closeAccount: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('closeAccount: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to close the account');
        }
      } else {
        reject(`closeAccount: No error and no response`);
      }
    });
  });
};

export const fetchDaywiseReport = (opts = {}) => {
  return new Promise((resolve, reject) => {
    superagent.get(config.serverHost + '/apis/reports/daywise').query({
      username: opts.username,
      broker: opts.broker, 
      from: opts.startDateStr,
      to: opts.endDateStr
    }).end((err, resp) => {
      if (err) {
        console.error('fetchDaywiseReport: request failed. err => ', err);
        reject(resp.body);
      } else {
        console.log('fetchDaywiseReport: resp body = ', resp.body);
        resolve(resp.body);
      }
    });
  });
};

export const fetchServerConfig = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/public/config", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchServerConfig: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('fetchServerConfig: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('fetchServerConfig: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch system config');
        }
      } else {
        reject(`fetchServerConfig: No error and no response`);
      }
    });
  });
};

export const fetchFAQs = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/utils/faq", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchFAQs: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('fetchFAQs: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('fetchFAQs: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch FAQs');
        }
      } else {
        reject(`fetchFAQs: No error and no response`);
      }
    });
  });
};

export const fetchBrokerLoginRules = (broker = '') => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/utils/broker/login/rules?broker=" + broker , { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchBrokerLoginRules: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('fetchBrokerLoginRules: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('fetchBrokerLoginRules: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch broker login rules');
        }
      } else {
        reject(`fetchBrokerLoginRules: No error and no response`);
      }
    });
  });
};

export const fetchAllStrategies = (opts = {}) => {
  const publicOnly = opts.publicOnly || false;
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/strategies?publicOnly=" + publicOnly, { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchAllStrategies: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('fetchAllStrategies: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('fetchAllStrategies: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch all strategies');
        }
      } else {
        reject(`fetchAllStrategies: No error and no response`);
      }
    });
  });
};

export const fetchAllBrokers = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/brokers", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchAllBrokers: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('fetchAllBrokers: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('fetchAllBrokers: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch all brokers');
        }
      } else {
        reject(`fetchAllBrokers: No error and no response`);
      }
    });
  });
};

export const fetchAllocationModels = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/allocationmodels", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('fetchAllocationModels: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('fetchAllocationModels: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('fetchAllocationModels: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch allocation models');
        }
      } else {
        reject(`fetchAllocationModels: No error and no response`);
      }
    });
  });
};

export const getUserBrokerPassword = (username, broker) => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + `/apis/broker/password?username=${username}&broker=${broker}`, { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getUserBrokerPassword: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getUserBrokerPassword: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getUserBrokerPassword: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch user broker password');
        }
      } else {
        reject(`getUserBrokerPassword: No error and no response`);
      }
    });
  });
};

export const getUserAlerts = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/user/alerts", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getUserAlerts: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getUserAlerts: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getUserAlerts: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch user alerts');
        }
      } else {
        reject(`getUserAlerts: No error and no response`);
      }
    });
  });
};

export const getUserPayments = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/user/payments", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getUserPayments: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getUserPayments: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getUserPayments: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch user payments');
        }
      } else {
        reject(`getUserPayments: No error and no response`);
      }
    });
  });
};

export const getBrokerDetails = (broker) => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/brokers" + (broker ? "?broker=" + broker : ""), { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getBrokerDetails: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getBrokerDetails: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getBrokerDetails: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch user plans');
        }
      } else {
        reject(`getBrokerDetails: No error and no response`);
      }
    });
  });
};

export const getUserPlans = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/user/plans", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getUserPlans: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getUserPlans: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getUserPlans: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch user plans');
        }
      } else {
        reject(`getUserPlans: No error and no response`);
      }
    });
  });
};

export const getAllPlans = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/plans", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getAllPlans: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getAllPlans: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getAllPlans: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch all plans');
        }
      } else {
        reject(`getAllPlans: No error and no response`);
      }
    });
  });
};

export const getAllBrokeragePlans = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/brokerage/plans", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getAllBrokeragePlans: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getAllBrokeragePlans: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getAllBrokeragePlans: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch all brokerage plans');
        }
      } else {
        reject(`getAllBrokeragePlans: No error and no response`);
      }
    });
  });
};

export const generateReferralCode = (data = {}) => {
  return new Promise((resolve, reject) => {
    HttpRequest.post({
      url: config.serverHost + "/apis/public/referralcode/generate",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      if (err) {
        console.log('generateReferralCode: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('generateReferralCode: status code = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200 || resp.statusCode === 201) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('generateReferralCode: end point not found');
        } else {
          console.error('generateReferralCode: error => ', respBody);
          reject((respBody || {}).error || 'Failed to generate referral code');
        }
      } else {
        reject('generateReferralCode: No error and no response');
      }
    });
  });
};

export const getSystemConfig = () => {
  return new Promise((resolve, reject) => {
    HttpRequest(config.serverHost + "/apis/config", { json: true }, (err, resp, respBody) => {
      if (err) {
        console.log('getSystemConfig: error = ', err);
        return reject(err);
      }
      if (resp) {
        console.log('getSystemConfig: resp.statusCode = ' + resp.statusCode);
        if (resp.statusCode !== 301 && resp.statusCode !== 302) {
          respBody = respBody && _.isString(respBody) ? JSON.parse(respBody) : respBody;
        }
        if (resp.statusCode === 200) {
          resolve(respBody);
        } else if (resp.statusCode === 404) {
          reject('getSystemConfig: end point not found');
        } else {
          reject((respBody || {}).error || 'Failed to fetch all brokerage plans');
        }
      } else {
        reject(`getSystemConfig: No error and no response`);
      }
    });
  });
};
